@import "@picocss/pico";

*{
    scroll-behavior: smooth;
}

body{
    font-size:16px;
}
.sr-only {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}
.custom-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-title {
    font-size: 1.8rem;
    margin: 0;
}
.text-subtitle{
    font-size: 1.2rem;
    margin: 0;
}
.text{
    font-size: 1rem;
    margin: 0;
}
section{
    margin: 0;
}
.search_bar{
    margin: 20px 0;
}
.search_bar input[type=search]{
    border-radius: 0.25rem !important;
}
.contrast{
    border-radius: 0 !important;
    border: none !important;
    background-color: #fff !important;
    color: #000 !important;
    text-decoration: underline !important;
    font-size: 0.8rem !important;
    margin: 10px 0 !important;
    padding: 0 !important;
}

/* header */

.header{
    background-color: #9281E7;
    position: fixed;
    width: 100%;
}
.header a {
    color: #415462;
    transition: 0.3s;
    color: white;
}
.header a:hover {
    color: #000;
}
#navigation-mobile{
    display: none;
}
.header ul:first-child{
    width: 50px;
    height: auto;
}
.header img{
    width: 100%;
    height: auto;
}
.header .navigation{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* landing */

.landing{
    background-color: #9281E7;
    padding:90px 0 20px;
}
.landing .text-title,
.landing .text-subtitle{
    margin: 0 0 20px;
}
.landing .custom-flex_column{
    width: 50%;
}

/* search */

.search{
    height: 100%;
    padding: 20px;
}
.search .custom-flex{
    justify-content: space-between;
}
.search .search-select{
    margin: 0;
    min-width: 200px;
}
.search .custom-flex:last-child{
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 50px 0;
    gap: 20px;
}
.card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 23%;
    min-width: 300px;
    display: flex;
}
.card-header{
    width: 15%;
}
.card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    min-height: 200px;
    width: 100%;
}
.tag {
    background: #cccccc;
    border-radius: 50px;
    font-size: 12px;
    margin: 0;
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    cursor: pointer;
}
.tag-teal {
    background-color: #4F33D8;
    margin: 0 0 10px 0 ;
}
.tag-purple {
    background-color: #5e76bf;
}
.tag-pink {
    background-color: #cd5b9f;
}
.card-body .text {
    font-size: 0.875em;
    text-align: right;
    width: 100%;
}
.card-body .text-subtitle {
    font-size: 1em;
    margin: 20px 0;
}

/* newsletter */
.newsletter{
    padding: 40px;
    background-color: #9281E7;
    text-align: center;
}
.newsletter .newsletter-text{
    padding: 0 0 20px 0;
}
.newsletter .newsletter-text h3{
    color: white;
}
.newsletter .newsletter-text p{
    padding: 10px 0 20px;
    color: white;
}
.newsletter .newsletter-form{
    width: 40%;
    margin: 0 auto;
}
.newsletter .newsletter-form input{
    border: 1px solid #FFFFFF;
    border-radius: 0;
    color: #9281E7;
    background-color: white;
}
.newsletter .newsletter-form input::placeholder{
    color: #9281E7;
}
.newsletter .newsletter-form button{
    width: 70%;
    border: 1px solid white;
    margin: 0 auto;
    background-color: #9281E7;
    color: white;
}
.newsletter .newsletter-form button:hover{
    background-color: white;
    color: #9281E7;
}
/* pagination */

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagination  button{
    background-color: #fff;
    border: 1px solid #ccc;
    color: #000;
    cursor: pointer;
    font-size: 1em;
    padding: 10px;
    transition: 0.3s;
    width: 50px;
    height: auto;
    margin: 0 20px;
}
.pagination  button:hover,
.pagination  button.active{
    background-color: #9281E7;
    color: #fff;
}

/* footer */
.footer hr{
    border: 1px solid #e4edf4;
}
.footer .custom-flex{
    margin: 20px 0;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
}
.footer .custom-flex ul li{
    list-style-type: none;
}
.footer .custom-flex .footer-container-information{
    width: 33%;
}
.footer .custom-flex .footer-container-information div.logo-footer{
    margin: 0 auto 5%;
}
.footer .custom-flex .footer-container-information ul{
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: center;
}
.custom-flex_column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.footer .rs{
    height: 100%;
    display: flex;
    align-items: center;
}
.footer .custom-flex .footer-container-information ul li{
    width: 25px;
    margin: 0 5% 0 0;
}
.footer .custom-flex .footer-container-information .rs img{
    width: 100%;
}
.footer .custom-flex .footer-container-information div.copyright{
    text-align: center;
}
.footer .title-footer{
    font-weight: bold;
    color: black;
}
.title-footer.suivi{
    margin-bottom: 0;
}
.footer .text{
    text-decoration: none;
    color: black;
    font-size: 16px;
}
.footer ul{
    padding: 0;
}
.footer .logo-footer{
    width: 50px;
    height: auto;
}
.footer img{
    width: 100%;
    height: auto;
}

/* responsive */

@media (max-width: 767px) {

    .header .logo{
        z-index: 11;
    }
    .header img{
        padding: 6px;
        margin-left: 9px;
    }
    .header .navigation-menue {
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        margin: 0 0 0 -20px;
        align-items: center;
        justify-content: center;
        right: 100%;
        top: 0;
        transition: 0.3s ease-in-out;
    }
    .header .navigation-menue.close {
        right: 0;
    }

    #navigation-mobile{
        border:none;
        background-color: transparent;

        width: 27px;
        height: 25px;
        z-index: 10;
        cursor: pointer;
        position: relative;
        padding: 5px 0;
        margin: 15px;
        display: block;
    }
    #navigation-mobile span {
        border-radius: 2px;
        position: absolute;
        display: block;
        height: 5px;
        width: 100%;
        background-color: white;
        transform: rotate(0);
        transition: all 200ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
    #navigation-mobile.close span {
        background-color: #000;
    }

    #navigation-mobile span.one {
        top: 0;
    }
    #navigation-mobile span.two {
        top: 10px;
    }
    #navigation-mobile span.three {
        top: calc(100% - 5px);
    }

    #navigation-mobile.close span.one {
        transform: translateY(10px) rotate(45deg);
    }
    #navigation-mobile.close span.two {
        opacity: 0;
    }
    #navigation-mobile.close span.three {
        transform: translateY(-10px) rotate(-45deg);
    }
    .navigation-menue.close > li > a{
        color: #415462;;
    }

    .landing img{display: none;}
    .card{
        width: 45%;
    }
    .landing .custom-flex,
    .search .custom-flex:first-child{
        flex-direction: column;
    }
    .search .custom-flex_column,
    .landing .custom-flex_column{
        width: 100%;
    }
    .search .search-select{
        margin: 20px 0;
    }

    .newsletter{
        padding: 40px 0px;
    }
    .newsletter .newsletter-form{
        width: 100%;
    }
    .footer .custom-flex .footer-container-information{
        width: 100%
    }
}
@media (max-width: 479px) {
    .footer .custom-flex{
        align-items: stretch;
        justify-content: center;
        text-align: center;
    }
    .footer .logo-footer{
        margin: 0 auto;
    }

    .footer .custom-flex .footer-container-information div.copyright {
        max-width: 100%;
    }
    .pagination  button{
        margin: 0 5px;
        width: 30px;
    }

    .rs ul{justify-content: center}
}
dialog.show{
    display: flex;
}
dialog.show article{
    margin: 25% auto;
}
dialog span.tag{
    margin-left: 10px;
}
.landing h1, .landing h2, .landing p{
    color: white;
}